// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-admissions-js": () => import("./../../../src/pages/admissions.js" /* webpackChunkName: "component---src-pages-admissions-js" */),
  "component---src-pages-become-teacher-js": () => import("./../../../src/pages/become-teacher.js" /* webpackChunkName: "component---src-pages-become-teacher-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-course-details-five-js": () => import("./../../../src/pages/course-details-five.js" /* webpackChunkName: "component---src-pages-course-details-five-js" */),
  "component---src-pages-course-details-four-js": () => import("./../../../src/pages/course-details-four.js" /* webpackChunkName: "component---src-pages-course-details-four-js" */),
  "component---src-pages-course-details-js": () => import("./../../../src/pages/course-details.js" /* webpackChunkName: "component---src-pages-course-details-js" */),
  "component---src-pages-course-details-six-js": () => import("./../../../src/pages/course-details-six.js" /* webpackChunkName: "component---src-pages-course-details-six-js" */),
  "component---src-pages-course-details-three-js": () => import("./../../../src/pages/course-details-three.js" /* webpackChunkName: "component---src-pages-course-details-three-js" */),
  "component---src-pages-course-details-two-js": () => import("./../../../src/pages/course-details-two.js" /* webpackChunkName: "component---src-pages-course-details-two-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-2-js": () => import("./../../../src/pages/index-2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-index-3-js": () => import("./../../../src/pages/index-3.js" /* webpackChunkName: "component---src-pages-index-3-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-details-js": () => import("./../../../src/pages/news-details.js" /* webpackChunkName: "component---src-pages-news-details-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-teacher-details-js": () => import("./../../../src/pages/teacher-details.js" /* webpackChunkName: "component---src-pages-teacher-details-js" */),
  "component---src-pages-teachers-js": () => import("./../../../src/pages/teachers.js" /* webpackChunkName: "component---src-pages-teachers-js" */)
}

